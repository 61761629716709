.footer{
	background:$color12;
	padding:40px 35px;

		@media (max-width:$screen-md - 1px){
			padding:40px 20px;
		}

	&__text{
		font-family: "Roboto";
		font-size:14px;
		color:$white;
		line-height: 120%;
	}

	&__contact{
		margin-top:33px;
	}

	&__address{
		margin-top:20px;
	}

	&__phone{
		
		margin-top:30px;
	}

	&__link{
		display: block;
		color:$white;
		&:hover,&:focus{
			text-decoration: none;
			color:$white;
		}
	}

	&__mail{
		margin-top:15px;
	}

	&__realization{
		color:$white;
		text-align: right;
		font-size: 12px;
		position:relative;
		top:-10px;

		@media (max-width:$screen-sm - 1px){
			text-align: left;
			margin-top:50px;
		}
	}

	&__logo{
		position:relative;
		top:-3px;
		margin-left:10px;

		@media (max-width:$screen-md - 1px){
			margin-left:5px;
		}

		@media (max-width:$screen-sm - 1px){
			margin-left:0;
			margin-top:10px;
		}
	}

	&__policy{
		text-align:right;
		margin-top:12px;
			
			@media (max-width:$screen-sm - 1px){
				text-align:left;
			}


			a{
				color:$white;
				text-decoration: underline;
			}


	}

	&__note{
		display: block;
		text-align: right;
		color:$white;
		text-decoration: underline;
		margin-top:7px;

			@media (max-width:$screen-sm - 1px){
				text-align: left;
			}

		&:hover,&:focus{
			color:$white;
		}
	}
}