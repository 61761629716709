html, body{
	font-family: 'Open Sans', sans-serif;
	overflow-x:hidden;

}

.clear{
	clear:both;
}

.mainContent{
margin-left:364px;
	@media (max-width:$screen-md - 1px){
		margin-left:0;
	}
}

.img-max{
	width:100%;
	height:auto;
}

.overflow{
	overflow:hidden;
}



.policy{
	padding: 100px 100px;
	background:$color15;
		
		@media (max-width:$screen-md - 1px){
			padding:40px 15px;
		}

	&__title{
		font-size:18px;
		font-weight:bold;
		text-align: center;
		margin-bottom:20px;
	}

	&__content{
		max-width: 75%;
		font-size:18px;
		margin:0 auto;

		@media (max-width:$screen-md - 1px){
			max-width: 100%;
		}
	}
}