.offer{
	background:$color7;
	padding:30px 0;
	padding-bottom:105px;

		@media (max-width:$screen-md - 1px){
			padding:20px 0;
			padding-bottom:50px;
		}

	&__title{
		font-size:53px;
		color:$color4;
		font-weight:300;
		text-align: center;
		text-transform: uppercase;
		margin-bottom:40px;

		@media (max-width:$screen-md - 1px){
			font-size:34px;
			margin-bottom:20px;
		}
	}

	&__link{
		display: block;

		&:hover,&:focus{
			text-decoration: none;
		}

		@media (max-width:$screen-sm - 1px){
			height:253px !important;
		}

	}

	&__outside{
		background:#E8E8E8;
		padding:0px 15px;
		height:32vw;
		position:relative;

		@media (max-width:$screen-sm - 1px){
			max-width: 320px;
   			 margin: 0 auto;
		}
	}

	&__text{
		color:$color4;
		font-size: 1.14vw;
		font-weight: 400;
		text-transform:uppercase;
		font-family:'Roboto';
		line-height: 120%;
		position: relative;	
		z-index:10;
			
			@media (max-width:1650px){
				font-size:1.1vw;
			}

			@media (max-width:$screen-md - 1px){
				font-size:14px;
			}

			@media (max-width:$screen-sm - 1px){
				font-size:20px;
			}


			&--outside{
				width:10vw;
    			top: 3.3vw;
    			left:5.3vw;

    			@media (max-width:1650px){
    				left:4.5vw;
    			}
				
				@media (max-width:1300px){
					left:3.5vw;
				}

				@media (max-width:$screen-md - 1px){
					width:191px;
					margin:0 auto;
				}

				@media (max-width:$screen-sm - 1px){
					width:172px;
					left:15px;
					top:15px;
					position:absolute;
				}
			}

			&--inside{
				width:155px;
		
				left: 1.1vw;
				top: 8.1vw;


				@media (max-width:$screen-md - 1px){
					top:7vw;
				}

				@media (max-width:$screen-sm - 1px){
					top:15px;
					left:15px;
				}

			}

			&--gateway{
			    position:absolute;
			    top:20px;
			    width: 12vw;
			    left:20px;
					

				@media (max-width:$screen-md - 1px){
					left:10px;
					top:10px;
					width:20vw;
				}

				@media (max-width:$screen-sm - 1px){
					width:200px;
				}
			

			}

			&--awnings{
			    width: 15vw;
			    margin-left: 6vw;
			    top: 9.5vw;

			    @media (max-width:1650px){
			    	margin-left:5vw;
			    }

			    @media (max-width:1300px){
			    	margin-left:4vw;
			    	top:9.75vw;
			    }

			    @media (max-width:$screen-md - 1px){
			    	width:165px;
			    	margin-left:4vw;
			    	top:8vw;
			    }

			    @media (max-width:$screen-sm - 1px){
			    	width:238px;
			    	top:auto;
			    	bottom:60px;
			    	position:absolute;
			    	right:20px;
			    }


			}

			&--windows{
			    margin-left: 1.2vw;
			    top: 10.7vw;

			    @media (max-width:$screen-md - 1px){
			    	top:10vw;
			    }

			    @media (max-width:$screen-sm - 1px){
			    	top:15px;
			    	left:15px;
			    }
			}
			&--doors{
			    width: 10vw;
			    margin-left: 1vw;
			    top: 8vw;

			    @media (max-width:$screen-md - 1px){
			    	top:6vw;
			    }

			    @media (max-width:$screen-sm - 1px){
			    	left:15px;
			    	top:110px;

			    }
			}
	}

	&__button{
		color:$color2;
		border:1px solid $color2;
		text-align: center;
    	padding: 0.5vw 0;
    	text-transform: uppercase;
    	width: 7.2vw;
   		font-size: 1vw;
   		position: relative;
   		z-index:10;

			@media (max-width:$screen-md - 1px){
				font-size:12px;
				width:80px;
			}

			@media (max-width:$screen-sm - 1px){
				    width: 117px;
    				padding: 8px 0;
    				font-size: 15px

			}

    	&--outside{
		    top: 4vw;
		    left: 5.3vw;

		    @media (max-width:1650px){
		    	left:4.5vw;
		    }

		    @media (max-width:1300px){
		    	left:3.5vw;
		    }

		    @media (max-width:$screen-md - 1px){
		    	margin:0 auto;
		    	left:0;
		    }

		    @media (max-width:$screen-sm - 1px){
		    	position:absolute;
		    	left:15px;
		    	top:70px;
		    }
    	}

    	&--inside{
		    top: 8.8vw;
		    left: 1.1vw;

		    @media (max-width:$screen-md - 1px){
		    	top:7.5vw;
		    }

		    @media (max-width:$screen-sm - 1px){
		    	top:95px;
		    	left:15px;
		    	position:absolute;
		    }
    	}

    	&--gateway{
    		position:absolute;
    		left:20px;
    		top:4.5vw;
			
			@media (max-width:$screen-md - 1px){
				left:10px;
				top:4vw;
			}

			@media (max-width:1050px){
				top:5vw;
			}

			@media (max-width:900px){
				top:5.5vw;
			}

			@media (max-width:$screen-sm - 1px){
				top:65px;
			}
    
    	}

    	&--awnings{
    		margin-top:10vw;
    		margin-left:6vw;

    		@media (max-width:1650px){
    			margin-left:5vw;
    		}

			@media (max-width:1300px){
				margin-left:4vw;
			}

			@media (max-width:$screen-md - 1px){
				margin-top:8vw;
			}

			@media (max-width:$screen-sm - 1px){
				  position: absolute;
				  bottom: 15px;
				  right: 140px;
			}

    	}

    	&--windows{
		    margin-left: 1.2vw;
		    top: 11.4vw;

		    @media (max-width:$screen-md - 1px){
		    	top:10.4vw;
		    }

		    @media (max-width:$screen-sm - 1px){
		    	left:15px;
		    	top:20px;
		    }
    	}

    	&--doors{
    		margin-left:1vw;
    		top:8.6vw;

    		@media (max-width:$screen-md - 1px){
    			top:6.2vw;
    		}

    		@media (max-width:$screen-sm - 1px){
    			left:15px;
    			bottom:25px;
    			top:auto;
    			position:absolute;

    		}
    	}
	}

	&__blinds{
		position: absolute;
		bottom:9px;
		left:15px;
		width:18vw;

		@media (max-width:1400px){
			width:16vw;
		}

		@media (max-width:$screen-md - 1px){
			left:0;
			right:0;
			margin:0 auto;
		}

		@media (max-width:$screen-sm - 1px){
			width: 246px;
		    bottom: -85px;
		    right: -40px;
		}
	}

	&__arrow{
		position: relative;
    	left: 5px;
    	top:-1px;
	}

	&__inside{
		background:$color9;
		position:relative;
		height:16vw;
			@media (max-width:$screen-sm - 1px){
			max-width: 320px;
   			 margin: 0 auto;
		}
	}

	&__blindsIn{
		position:absolute;
    	bottom: 0px;
    	right: -24px;
    	width:13.4vw;

    	@media (max-width:$screen-sm - 1px){
    		width:169px;
    		right:0;
    	}
	}

	&__gateway{
		background:$color10;
		position:relative;
		height:16vw;
			@media (max-width:$screen-sm - 1px){
			max-width: 320px;
   			 margin: 0 auto;
		}

		&-img{
			position: absolute;
			right:0;
			bottom: -7px;
			width: 13vw;
			
			@media (max-width:$screen-md - 1px){
				width:16vw;
			}

			@media (max-width:1050px){
				width:13vw;
			}

			@media (max-width:$screen-sm - 1px){
				width:210px;
				right:0px;
				bottom:0;
			}
		}
	}

	&__gatewayRolling{
		&-img{
			position:absolute;
			right:0;
			width:10vw;

			@media (max-width:$screen-sm - 1px){
				width:160px;
			}
		}
	}

	&__awnings{
		position:relative;
		background:$color11;
		height:16vw;
			@media (max-width:$screen-sm - 1px){
			max-width: 320px;
   			 margin: 0 auto;
		}
		&-img{
			position:absolute;
			left:0;
			top:0;
    		width: 14.5vw;

    		@media (max-width:$screen-md - 1px){
    			width:12vw;
    		}

    		@media (max-width:$screen-sm - 1px){
    			width:218px;
    		}
		}
	}

	&__windows{
		position:relative;
		background:$color7;
		height:16vw;
			@media (max-width:$screen-sm - 1px){
			max-width: 320px;
   			 margin: 0 auto;
		}

		&-img{
			position:absolute;
			right:-105px;
			bottom:0;
			width:19vw;

			@media (max-width:$screen-md - 1px){
				right:-50px;
			}

			@media (max-width:$screen-sm - 1px){
				right: 0px;
    			width: 230px;
			}
		}
	}

	&__doors{
		background:$color11;
		position:relative;
		height:16vw;
			@media (max-width:$screen-sm - 1px){
			max-width: 320px;
   			 margin: 0 auto;
		}

		&-img{
			position:absolute;
			right:0;
		    top: 1vw;
		    width: 10.2vw;

		    @media (max-width:$screen-sm - 1px){
		    	width:135px;
		    	top:30px;
		    	right:15px;
		    }
		}
	}
}