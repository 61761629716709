.top{
	background:url('/media/default/topBg.jpg') no-repeat;
	background-size:cover;
	position:relative;
	padding-top:44px;
	padding-left:40px;
	padding-bottom:80px;

		@media (max-width:$screen-md - 1px){
			padding:0 15px;
			padding-top:40px;
			padding-bottom:40px;
		}

		@media (max-width:$screen-sm - 1px){
			padding-top:30px;
			padding-bottom:200px;
		}

	&__orderText{
		color:$color2;
		font-size: 34px;
		font-weight: 600;
		text-transform: uppercase;
		position:relative;
		z-index:1;
		line-height: 120%;

		@media (max-width:$screen-md - 1px){
			text-align:center;
			max-width: 600px;
			margin:0 auto;
		}

		@media (max-width:$screen-sm - 1px){
			font-size:20px;
		}
	}


	.youGain{
		position:relative;
		z-index:1;
		margin-top: 45px;
   		margin-left: 85px;
			
			@media (max-width:$screen-md - 1px){
				margin-left:0;
				max-width: 640px;
				margin:0 auto;
				margin-top:45px;
			}

			@media (max-width:$screen-sm - 1px){
				margin-top:20px;
			}

		&__column{
			float:left;
			font-size:26px;
			font-weight: 400;
			color:$color4;

			@media (max-width:$screen-sm - 1px){
				font-size: 20px;
				float:none;
			}
		}

		&__point{
			font-size: 20px;
   			margin-left: 20px;
   			margin-top: 5px;
   			margin-bottom:15px;

   			@media (max-width:$screen-sm - 1px){
   				font-size: 16px;
   				margin-left:0;
   			}

		}

		&__ok{
			margin-right:20px;

			@media (max-width:$screen-sm - 1px){
				width:20px;
				margin-right: 10px;
			}
		}

		&__span{
			font-size:16px;

			@media (max-width:$screen-sm - 1px){
				font-size:13px;
			}
		}
	}

	.call{
		position: relative;
		z-index:1;
		margin-top:15px;
				
				@media (max-width:$screen-md - 1px){
					margin:0 auto;
					margin-top:15px;
					max-width:640px;
				}

		&__column{
			float:left;
			font-size: 33px;
			font-weight:400;
			color:$color2;

			@media (max-width:$screen-sm - 1px){
				font-size: 30px;

			}
		}

		&__phone{
			margin-left:15px;
			font-weight: bold;
			display: block;
			color:$color2;
				&:hover,&:focus{
					text-decoration: none;
					color:$color2;
				}

				@media (max-width:$screen-md - 1px){
					&--ft{
						display: none;
					}
				}

				@media (max-width:$screen-sm - 1px){
					margin-left:10px;
				}
		}
	}

	&__form{
		position:relative;
		z-index:1;
		max-width: 640px;
		margin-top:15px;
			
			@media (max-width:$screen-md - 1px){
				margin:0 auto;
			}

			@media (max-width:$screen-sm - 1px){
				max-width: 320px;
			}
			

		&-text{
			color:$color2;
			font-size: 24px;
			font-weight: 300;

			@media (max-width:$screen-sm - 1px){
				font-size: 18px;
			}
		}

		&-block{
			margin-top:20px;
		}

		&-input{
			border:2px solid $color5;
			margin-bottom:5px;
			max-width: 313px;
			display: inline-block;
			padding:0px 10px;
			height:44px;
			margin-left:2px;
			background:transparent;
			color:$color4;
			font-size: 15px;
			font-weight: 300;

				@media (max-width:$screen-sm - 1px){
					min-width: 279px;
					max-width: 100%;
					display: block;
				}

			&::placeholder{
				color:$color4;
				font-size: 15px;
				font-weight: 300;
			}
		}

		&-textarea{
		width:635px;
		max-width: 631px;
		height:93px !important;
		padding:5px 10px;

			@media (max-width:$screen-sm - 1px){
				min-width: 279px;
				max-width: 100%;

			}
		}

		&-checkbox{
			width:22px;
			height:22px;
			background:$black;
			position:relative;
			top:-8px;
			left:2px;
			float:left;

			@media (max-width:$screen-sm - 1px){
				top:0;
			}


			&--scd{
				top:3px;

				@media (max-width:$screen-sm - 1px){
					top:10px;
				}
			}	


			
			&::before{
			content: '';
		    display: block;
		    position: absolute;
		    top: 0;
		    left: 0;
		    width: 22px;
		    height: 22px;
		    background-color: $black;
		    border: 0;
		    z-index: 1;
			}

			&:checked{
				&::after{
					content: '';
				    display: block;
				    position: absolute;
				    top: 4px;
				    left: 5px;
				    width: 12px;
				    height: 8px;
				    border-left: 3.5px solid $white;
				    border-bottom: 3.5px solid $white;
				    transform: rotate(-45deg);
				    z-index: 2;
	
				}
			}
		}

		&-consent{
			font-size: 12px;
			font-weight: 300;
			color:$color4;
			position:relative;
			top:-3px;
			left:20px;
			float:left;
			max-width: 599px;

			@media (max-width:1300px){
				max-width: 500px;
			
}
			&--scd{
				margin-top:10px;

				@media (max-width:$screen-md - 1px){
					max-width:470px;
				}
			}

			@media (max-width:$screen-sm - 1px){
				min-width:240px;
				max-width: 90%;
				top:0;
			}



		}

		&-submit{
			background:$color2;
			color:$white;
			border:0;
			font-size: 18px;
			font-weight: 400;
			text-align: center;
			text-transform: uppercase;
			margin-right: 5px;
			white-space: nowrap;
			width:313px;
			white-space: normal;
			margin-top:15px;
			padding:5px 20px;
			padding-bottom:8px;
			float:right;


			@media (max-width:1700px){
				float:none;
			}


				@media (max-width:$screen-md - 1px){
					float:none;
				}

				@media (max-width:$screen-sm - 1px){
					max-width: 248px;
				}



		}

		&-ps{
			max-width: 313px;
			float:right;
			font-size:12px;
			color:$color4;
			font-weight: 300;
			margin-top:20px;

			@media (max-width:1700px){
				float:none;
			}

			@media (max-width:$screen-md - 1px){
				float:left;
			}

			@media (max-width:$screen-sm - 1px){
				max-width:260px;
			}
		}
	}

	
	&__man{
		position: absolute;
		right:0;
		bottom:0;
		z-index:0;
		width:45vw;

		@media (max-width:1300px){
			width:35vw;
		}

		@media (max-width:$screen-sm - 1px){
			width:370px;
			right:-150px;
		}

	}
}

.required{
	font-size:14px;
	color:$black;
	font-weight:400;

	&__field{
		color:$color4;
		font-size: 12px;
		font-weight: 300;
		margin-left:25px;
		margin-top:5px;
	}
}