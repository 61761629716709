.page{
	&--awnings{
		padding-bottom:0;
	}

	&__header{
		&--awnings{

			background:url('/media/default/awningsBg.jpg') no-repeat;
			background-size:cover;
		
		}


		&--insideBlinds{
			background:url('/media/default/blindBg.jpg') no-repeat;
			background-size:cover;
		}

		&--outsideBlinds{
			background:url('/media/default/outsideBlindsBg.jpg') no-repeat;
			background-size:cover;
		}
	}

	&__content{
		&--awnings{
			max-width: 495px;
   			margin-top: 20%;
			padding: 0 10px;

			@media (max-width:$screen-md - 1px){
				max-width: 290px;
				margin-top:15%;
			}

			@media (max-width:$screen-sm - 1px){
				padding:0 30px;
				margin-top:30px;
				margin-bottom:20px;
				max-width: 100%;
			}
		}

		
		&--insideBlind{
			margin:0 auto;;
			padding:0;
			max-width: 1200px;
			font-size: 24px;
			font-weight: 300;
			text-align: center;
			margin-bottom:70px;

			@media (max-width:$screen-md - 1px){
				font-size: 16px;
				margin-bottom: 40px;
			}
		}

		&-outsideBlind{
			background:$color23;

			&-text{
				max-width: 500px;
				font-size: 18px;
				color:$color27;
				margin:0 auto;
				margin-top:10%;
				padding:15px 15px;

				@media (max-width:$screen-md - 1px){
					font-size: 16px;
					margin-top:0;
				}
			}
		}

		&--gatewayRolling{
			margin-top:12%;
		}
	}

	&__image{
		&--awnings{
			background:url('/media/default/awningsMain.jpg') no-repeat;
			min-height: 833px;
			background-size:cover;
		}

		&--outsideBlind{
			background:url('/media/default/outsideBlindMain.jpg') no-repeat;
			min-height: 710px;
			background-size:cover;
			&-sm{
				display: none;
				@media (max-width:$screen-sm - 1px){
					display: block;
				}
			}
		}
	}

		&__top{
		background:$color14;
		padding:0 15px;
		padding-top:100px;
		padding-bottom:50px;


		@media (max-width:$screen-md - 1px){
			padding-top:30px;
			padding-bottom:40px;
		}
	}	

	&__weDealWith{
		background:$color23;
		padding:50px 40px;
		&-text{
			font-size: 26px;
			font-weight: 300;
			color:$color24;
			line-height: 120%;

			@media (max-width:$screen-md - 1px){
				font-size: 18px;
			}

			@media (max-width:$screen-sm - 1px){
				font-size: 16px;
			}

		}

		@media (max-width:$screen-md - 1px){
			padding:30px 15px;

		}

		@media (max-width:$screen-sm - 1px){
			padding:15px 15px;
		}
	}
}


.products{
	background:$white;
	padding:0 40px;
	padding-top:55px;
	position:relative;

		&::after{
			content:'';
			display:block;
			position:absolute;
			bottom:0;
			width:100%;
			height:5px;
			background:$white;
		}
	
	@media (max-width:$screen-md - 1px){
		padding:0px;

	}


	
	&__block{
		margin-top:25px;
		padding-bottom:30px;
		border-bottom:1px solid $color22;

	}

	&__left{
		float:right;
		width:69.66%;
		padding:0 15px;

		@media (max-width:$screen-md - 1px){
			float:none;
			width:100%;
			padding:20px 15px;
		}
	}

	&__right{
		float:left;
		width:30.33%;
		padding:0 15px;
	@media (max-width:$screen-md - 1px){
			float:none;
			width:100%;
		}
	}

	&__title{
		font-size: 28px;
		font-weight: 400;
		color:$color20;
		position: relative;
		margin-left:23px;
		&::before{
			content:'';
			width:18px;
			height:20px;
			display:block;
			background:url('/media/default/productsArrow.png') no-repeat;
			position:absolute;
			top:12px;
			left:-27px;

			@media (max-width:$screen-md - 1px){
				top:8px;
			}
		}

		@media (max-width:$screen-md - 1px){
			font-size:24px;
		}
	}

	&__content{
		font-size: 18px;
		line-height: 120%;
		color:$color21;
		font-weight: 300;
		margin-top:25px;

		@media (max-width:$screen-md - 1px){
			font-size: 16px;
		}

		&--insideBlinds{
			max-width: 550px;
		}
	}

	&__description{
		&-text{
			font-size: 23px;
			color:$color21;
			font-weight: 600;
			display: inline-block;
			margin-left:10px;

			@media (max-width:$screen-md - 1px){
				font-size:18px;
			}

			@media (max-width:$screen-sm - 1px){
				margin-left:0;
				font-size:16px;
			}
		}

		margin-top:40px;

		@media (max-width:$screen-md - 1px){
			margin-top:20px;
		}
	}

	&__button{
		display:inline-block;
		color:$color2;
		font-size: 16px;
		font-weight: 400;
		border:1px solid $color2;
		width:103px;
		padding:15px 0;
		text-align: center;
		margin-left:10px;
		position:relative;
		cursor: pointer;

		@media (max-width:$screen-md - 1px){
			padding:10px 0;
		}

		@media (max-width:$screen-sm - 1px){
			padding:5px 0;
			font-size:14px;
			width:90px;
			margin-left:5px;
		}

		&-arrow{
			position: absolute;
			right:16px;
			top:20px;
			transform: rotate(-180deg);
			-webkit-transform: rotate(-180deg);
			-moz-transform: rotate(-180deg);
			-o-transform: rotate(-180deg);
			-ms-transform: rotate(-180deg);

			@media (max-width:$screen-md - 1px){
				top:15px;
			}

			@media (max-width:$screen-sm - 1px){
				top:10px;
			}

			&.transform{
			transform: rotate(0deg);
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			-ms-transform: rotate(0deg);	
			}
		}
	}

	&__span{
		&--expand{
			display: block;
			margin-left:-20px;
		}

		&--collapse{
			position:relative;
			left:-5px;
			display:none;

		}
	}

	&__table{
		display: none;
	}


	table{
		margin-top:5px;
		width:100%;
	 	border-spacing: 5px;
	    border-collapse: separate;
	   
		tr{
			td{
				border:1px solid $color22;
				padding: 10px 10px;
				color:$color21;
				font-size: 16px;
				font-weight: 300;
					
					@media (max-width:$screen-sm - 1px){
						padding:5px 5px;
					}

				&:first-child{
					width:37%;
				}

			}
		}
	}
	
	&__gallery{
		max-width: 410px;
		margin:0 auto;
	}


	&__rowPhoto{
		margin:0 -8px;
	}

	&__photo{
		
	}

	&__titlePhoto{
		font-weight: 300;
		font-size: 14px;
		text-align: center;
		margin-top:15px;
		color:$color21;
		line-height: 120%;
	}

	&--insideBlinds{
		padding:0;
		margin:0;

		&::after{
			display: none;
		}
	}

	&__descriptionBlock{
		margin-top:10%;
		margin-left:8%;
		padding-bottom:15px;
		&--bright{
			margin-left:30%;

			@media (max-width:1600px){
				margin-left:15%;
			}

			@media (max-width:$screen-md - 1px){
				margin-left:5%;
			}
		}

		@media (max-width:$screen-sm - 1px){
			margin:0;
			margin-top:15px;
		}
	}

	&__box{
		&--dark{
			background:$color25;
		}

		&--bright{
			background:$color26;
		}
	}

	

	&__insideBlinds-img{
		background-size:cover !important;
		width:100%;
		height:32vw;

		@media (max-width:$screen-md - 1px){
			height:70vw;
		}

		@media (max-width:$screen-sm - 1px){
			display: none;
		}

		&--sm{
			display: none;
			@media (max-width:$screen-sm - 1px){
				display: block;
				margin:0 auto;
			}
		}
	}
}



.outsideBlind{
	background:$white;
	padding:0 40px;
	margin-top:30px;
	margin-bottom:50px;

		@media (max-width:$screen-sm - 1px){
			margin-top:15px;
			padding:0 20px;
		}

	&__left{
		float:left;
		width:10%;
		min-height: 5px;


		@media (max-width:$screen-md - 1px){
			width:9%;
		}

		@media (max-width:$screen-sm - 1px){
			display: none;
		}
	}

	&__right{
		float: left;
		width:85%;

		@media (max-width:$screen-md - 1px){
			width:90%;
			padding-left:10px;
		}

		@media (max-width:$screen-sm - 1px){
			width:100%;
			float:none;
			padding:0;
		}

	}

	&__title{
		@media (max-width:$screen-md - 1px){
			font-size: 20px;

			&::before{
				top:4px;
				left:-25px;

			}
		}
	}


	&__text{
		font-size: 18px;
		font-weight: 300;
		color:$color27;
		max-width: 1185px;

		@media (max-width:$screen-md - 1px){
			font-size: 16px;
		}
		
	}
	&__assembly{
		color:$color27;
		font-size: 18px;
		font-weight: 300;
		margin-top:30px;
		margin-bottom:30px;

		@media (max-width:$screen-md - 1px){
			font-size: 16px;
		}

		&-text{
			font-size: 15px;
			color:$color27;
			font-weight: 300;
			max-width: 350px;
			margin-bottom: 20px;


			@media (max-width:$screen-md - 1px){
				font-size: 14px;

				max-width: 280px;
			}

			span{
				font-size: 13px;
				display: block;

				@media (max-width:$screen-md - 1px){
					font-size: 12px;
				}
			}
		}

		&-img{
			max-width:90%;
		}
	}

	&__stage{
		font-size: 15px;
		color:$color27;
		font-weight: 300;
		margin-top:10px;

		@media (max-width:$screen-md - 1px){
			font-size: 14px;
		}

		&-bold{
			font-size: 18px;
			font-weight: bold;
			color:$color27;
			margin-bottom: 5px;

			@media (max-width:$screen-md - 1px){
				font-size: 16px;
			}
		}
	}

	&__block{
		max-width: 800px;
		margin-left:10%;

		@media (max-width:$screen-sm - 1px){
			margin-left: 0;
		}
	}
}
