.orderFreeMeasurement{
	background:$color5;
	color:$white;
	text-shadow:2px 2px 2px rgba(0,0,0,0.22);
	text-align:center;
	font-size:56px;
	font-weight:600;
	text-transform:uppercase;
	padding:45px 0;
	line-height:120%;

	@media (max-width:$screen-md - 1px){
		font-size:34px;
		padding:30px 0;
	}

	@media (max-width:$screen-sm - 1px){
		font-size:20px;
		padding:20px 10px;
	}
}

.orderFree{
	background:url('/media/default/orderFree.jpg') no-repeat;
	background-size:cover;
	padding-top:82px;
	padding-bottom:60px;
	position:relative;

		@media (max-width:$screen-md - 1px){
			padding-top:30px;
			padding-bottom:40px;
		}

		@media (max-width:$screen-sm - 1px){
			padding-top:20px;
			padding-bottom:200px;
		}

	&__block{
	}

	&__title{
		color:$color2;
		font-size: 44px;
		font-weight: 600;
		text-align: center;
		line-height: 120%;

		@media (max-width:$screen-md - 1px){
			font-size:28px;
		}

		@media (max-width:$screen-sm - 1px){
			font-size: 18px;
			margin:0 5px;
		}
	}

	&__subtitle{
		color:$color1;
		font-size: 32px;
		font-weight: 300;
		text-align: center;
		margin-top:15px;

			@media (max-width:$screen-md - 1px){
				font-size:24px;
			}

			@media (max-width:$screen-sm - 1px){
				font-size: 16px;
				padding:0 15px;
			}

		&--span{
			font-weight:600;
			text-transform: uppercase;
		}
	}

	&__text{
		max-width: 925px;
		margin:0 auto;
		font-size:18px;
		font-weight: 300;
		text-align:center;
		margin-top:60px;
			
			@media (max-width:$screen-md - 1px){
				font-size:16px;
				margin-top:30px;
			}

			@media (max-width:$screen-sm - 1px){
				font-size: 14px;
			}

		&--span{
			font-weight: bold;
		}
	}

	&__call{
		color:$color2;
		font-size: 22px;
		font-weight: 300;
		text-align: center;
		margin-top:40px;
		margin-left:15px;
		margin-right:15px;

		@media (max-width:$screen-md - 1px){
			font-size: 18px;
			margin-top:20px;
		}

		@media (max-width:$screen-sm - 1px){
			font-size:16px;
		}
	}
	
	&__contact{
		margin-top:45px;

			@media (max-width:$screen-md - 1px){
				margin-top:25px;
			}
	}
	&__link{
		line-height: 120%;
		display: block;
		text-align: center;
		font-size:33px;
		color:$color2;
		font-weight: bold;
			
			@media (max-width:$screen-md - 1px){
				font-size:24px;
			}

			@media (max-width:$screen-sm - 1px){
				font-size:18px;
			}

		&:hover,&:focus{
			text-decoration:none;
			color:$color2;
		}

		&--span{
			font-size:22px;

			@media (max-width:$screen-md - 1px){
				font-size:16px;
			}

			@media (max-width:$screen-sm - 1px){
				font-size:14px;
			}
		}
	}	


	&__man{
		position:absolute;
		right:0;
		bottom:0;
		z-index:0;
		width:45vw;

		@media (max-width:$screen-sm - 1px){
			width: 370px;
    		right: -150px;
		}
	}
}




.orderFreeForm{
	position:relative;
	z-index:1;
	&__text{
		font-size: 20px;
		color:$color2;
		font-weight: 300;
		max-width: 898px;
		margin-left:40px;
		margin-top:65px;

			@media (max-width:$screen-md - 1px){
				font-size:16px;
				margin-top:40px;
			}

			@media (max-width:$screen-sm - 1px){
				margin:0 15px;
				margin-top:30px;
			}
	}

	&__block{
		max-width: 635px;
		margin-left: 40px;

		@media (max-width:$screen-sm - 1px){
			margin:0 15px;
		}
	}
}