.map{
	height:721px;
	width:100%;

		@media (max-width:$screen-md - 1px){
			height:350px;
		}

		@media (max-width:$screen-sm - 1px){
			height:250px;
		}

	&__point{
		font-size:16px;
	}

	&__description{
		font-size:14px;
		margin-top:10px;
	}

}

.contact{
	background:$color19;
	padding-bottom:15px;
	position:relative;



		&__block{
		background:$color18;
		border:1px solid $white;
		margin:0 5%;
		margin-top:-225px;
		position:relative;
		z-index:10;
		padding-top:45px;
		padding-bottom:130px;

		@media (max-width:1700px){
			padding-bottom:60px;
		}

		@media (max-width:1300px){
			margin-left:2%;
			margin-right:2%;
		}

		@media (max-width:$screen-md - 1px){
			margin:0;
			padding-top:25px;
			padding-bottom:40px;
		}

		@media (max-width:$screen-sm - 1px){
			padding-bottom:0px;
		}
	}

	&__title{
		font-size: 70px;
		font-weight: 600;
		text-align:center;
		color:$color5;
		text-transform: uppercase;

		@media (max-width:$screen-md - 1px){
			font-size:34px;
		}
	}

	&__subtitle{
		color:$color5;
		font-size: 23px;
		font-weight: 600;
		text-align: center;
		margin-top:35px;

		@media (max-width:$screen-md - 1px){
			font-size: 18px;
			margin-top:15px;
		}
	}

	&__text{
		color:$color5;
		font-size: 14px;
		font-weight: 400;
		line-height:120%;
	}

	&__desc{
		max-width: 1000px;
		margin-top:48px;
		margin-left:15vw;

		@media (max-width:1700px){
			margin-left:10vw;
		}

		@media (max-width:1350px){
			margin-left:5vw;
		}

		@media (max-width:1199px){
			margin-left:15vw;
		}

		@media (max-width:1024px){
			margin-left:10vw;
		}

		@media (max-width:820px){
			margin-left:5vw;
		}

		@media (max-width:$screen-sm - 1px){
	    max-width: 800px;
    	margin-top: 48px;
    	margin-left: 20.5vw;
		}



	}

	&__address{
		position:relative;
		&::before{
			content:'';
			width:29px;
			height:44px;
			display: block;
			position:absolute;
			top:0;
			left:-65px;
			background:url('/media/default/miniMarker.png') no-repeat;

				@media (max-width:1450px){
					transform:scale(0.7);
					-webkit-transform:scale(0.7);
					-moz-transform:scale(0.7);
					-ms-transform:scale(0.7);
					-o-transform:scale(0.7);
					left:-45px;
				}

			@media (max-width:$screen-md - 1px){
				left:-35px;
			}

			@media (max-width:$screen-sm - 1px){
				left:-50px;
					transform:scale(1);
					-webkit-transform:scale(1);
					-moz-transform:scale(1);
					-ms-transform:scale(1);
					-o-transform:scale(1);	
			}
		}
	}


	&__phone{
		position:relative;
		margin-top:45px;
		&::before{
			content:'';
			width:41px;
			height:33px;
			display: block;
			position:absolute;
			top:0;
			left:-68px;
			background:url('/media/default/messages.png') no-repeat;

					@media (max-width:1450px){
					transform:scale(0.7);
					-webkit-transform:scale(0.7);
					-moz-transform:scale(0.7);
					-ms-transform:scale(0.7);
					-o-transform:scale(0.7);
					left:-48px;
				}
			

				@media (max-width:$screen-md - 1px){
				left:-40px;
			}

			@media (max-width:$screen-sm - 1px){
				left:-53px;
							transform:scale(1);
					-webkit-transform:scale(1);
					-moz-transform:scale(1);
					-ms-transform:scale(1);
					-o-transform:scale(1);	
			}
		}
	}

	&__open{
		position:relative;
		margin-top:35px;
			
			@media (max-width:$screen-sm - 1px){
				margin-bottom: 70px;
			}

		&::before{
			content:'';
			width:38px;
			height:37px;
			display: block;
			position:absolute;
			top:0;
			left:-70px;
			background:url('/media/default/clock.png') no-repeat;

				@media (max-width:1450px){
					transform:scale(0.7);
					-webkit-transform:scale(0.7);
					-moz-transform:scale(0.7);
					-ms-transform:scale(0.7);
					-o-transform:scale(0.7);
					left:-50px;
				}

				@media (max-width:$screen-md - 1px){
				left:-40px;
			}


			@media (max-width:$screen-sm - 1px){
				left:-55px;
					transform:scale(1);
					-webkit-transform:scale(1);
					-moz-transform:scale(1);
					-ms-transform:scale(1);
					-o-transform:scale(1);	
			}

		}

		&--right{
			margin-top:50px;
		}
	}

	&__day{
		margin-top:10px;
		float:left;
	}

	&__hour{
		float:left;
		margin-top:10px;
		margin-left:40px;
	}

	&__link{
		color:$color5;
		&:hover,&:focus{
			color:$color5;
			text-decoration: none;
		}
	}
	&__row{
		margin-left:0px;

		@media (max-width:$screen-sm - 1px){
			margin-left:-15px;
			margin-top:50px;
		}
	}

	&__addQr{
		color:$color16;
		font-size: 16px;
		font-weight: 600;
		text-align: center;
		margin:0 auto;
		margin-top:35px;
		line-height: 120%;
		max-width: 25vw;

			@media (max-width:1650px){
				max-width: 20vw;
			}

		@media (max-width:$screen-md - 1px){
			font-size:14px;
		}

		@media (max-width:$screen-sm - 1px){
			max-width:100%;
		}

		&--sm{
			display: none;

			@media (max-width:$screen-sm - 1px){
				display: block;
			}
		}

		&--hidden{
			@media (max-width:$screen-sm - 1px){
				display: none;
			}
		}
			

	}

	&__qr{
		display: block;
		margin:0 auto;
		margin-top:15px;
		width:14.2vw;

		@media (max-width:$screen-md - 1px){
			width:150px;
		}

		@media (max-width:$screen-sm - 1px){
			display: none;
		}


		
	}

	&__scanQr{
		position:absolute;
		bottom:0;
		left:0;
		width:33vw;
		z-index:11;

		@media (max-width:1800px){
			width:30vw;
		}

		@media (max-width:1425px){
			width: 28vw;
		}

		@media (max-width:$screen-md - 1px){
			width: 350px;
			left:-40px;
		}

		@media (max-width:$screen-sm - 1px){
			display: none;
		}
	}

	&__vcard{
		display: none;
		max-width: 250px;
		margin:0 auto;
		text-align: center;
		margin-top:10px;
		background:$color2;
		color:$white;
		font-weight: bold;
		font-size: 16px;
		padding:15px 5px;

		&:hover,&:focus{
			color:$white;
			text-decoration: none;
		}

		@media (max-width:$screen-sm - 1px){
			display: block;
		}
	}
}