.postedForm{
	height:725px;
	background:url('/media/default/postedFormBg.png') no-repeat;
	background-size:cover;
	padding:180px 60px;
	position:relative;

		@media (max-width:$screen-md - 1px){
			padding:40px 20px;
			height:400px;
		}

		@media (max-width:$screen-sm - 1px){
			height:350px;
		}

	&__title{
		font-size: 44px;
		font-weight: 300;
		color:$color4;
		max-width: 526px;
		line-height: 120%;
		position:relative;
		z-index: 0;

		@media (max-width:$screen-md - 1px){
			font-size:34px;
		}

		@media (max-width:$screen-sm - 1px){
			font-size: 20px;
		}

	}

	&__text{
		font-size: 21px;
		font-weight: 300;
		color:$color4;
		max-width: 650px;
		margin-top:50px;
		line-height: 120%;
		position:relative;
		z-index:1;

		@media (max-width:$screen-md - 1px){
			font-size: 18px;
			margin-top:20px;
			max-width: 550px;
		}

		@media (max-width:$screen-sm - 1px){
			font-size: 16px;
		}
	}

	&__img{
		position:absolute;
		right:0;
		bottom:0;
		width:45vw;
		z-index:0;

		@media (max-width:$screen-md - 1px){
			width:38vw;
		}

		@media (max-width:$screen-sm - 1px){
			display: none;
		}
	}
}