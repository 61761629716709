.whyChoose{
	background:url('/media/default/whyChooseBg.jpg') no-repeat;
	background-size:cover;
	padding:100px 0;

		@media (max-width:$screen-md - 1px){
			padding:50px 30px;
		}

		@media (max-width:$screen-sm - 1px){
			padding:25px 10px;
			padding-bottom:50px;
		}

	&__title{
		font-family: 'Roboto';
		font-weight: 300;
		font-size: 53px;
		color:$color2;
		text-transform: uppercase;
		margin-left:8%;
		line-height: 120%;

		@media (max-width: $screen-md - 1px){
			margin-left:0;
			font-size: 34px;
		}

		@media (max-width:$screen-sm - 1px){
			font-size:28px;
			margin:0 15px;
		}
	}

	&__span{
		font-weight: bold;

		&--underline{
			text-decoration: underline;
		}
	}

	&__ol{
	margin-left:7%;
	margin-top:107px;
	margin-right:15px;

		@media (max-width:$screen-md - 1px){
			margin-left:0;
		
		}

		@media (max-width:$screen-sm - 1px){
			padding:0 10px;
			width:100%;
			margin:0 15px;
			margin-top:30px;
		}
	}

	&__li{
		font-weight:400;
		font-size: 20px;
		color:$white;
		line-height: 120%;
		margin-bottom:40px;

		@media (max-width:$screen-md - 1px){
			font-size: 16px;
		}

		@media (max-width:$screen-sm - 1px){
			margin-bottom:20px;
			margin-right:10px;
		}
	}

	&__button{
		display: block;
		width:312px;
		margin:0 34%;
		margin-top:80px;
		padding:13px 0;
		color:$color2;
		border:2px solid $color2;
		text-align: center;
		font-size: 20px;
		font-family: 'Roboto';
		font-weight: 400;

		@media (max-width:$screen-md - 1px){
			max-width: 281px;
			margin:0 auto;
		}


		&:hover,&:focus{
			text-decoration: none;
			color:$color2;
		}
	}

	&__arrow{
		position: relative;
		left:7px;
	}
}