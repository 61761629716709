.page{
	background:$color15;

	&__header{
		height:372px;
		position: relative;
		text-align:center;

		@media (max-width:$screen-md - 1px){
			height:145px;
		}

		&--gateway{
			background:url('/media/default/gatewayBg.jpg') no-repeat;
			background-size:cover;
		}

		&--gatewayRolling{
			background:url('/media/default/gateway2Bg.jpg') no-repeat;
			background-size:cover;
		}

		&--doors{
			background:url('/media/default/doorBg.jpg') no-repeat;
			background-size:cover;
		}

		&--windows{
			background:url('/media/default/windowsBg.jpg') no-repeat;
			background-size:cover;
		}

	}

	&__title{
		font-size: 56px;
		font-weight: 600;
		color:$white;
		text-shadow: 2px 2px 2px rgba(0,0,0,0.36);
		position:absolute; 
		left:0;
		right:0;
		margin:auto;
		bottom:50px;
		text-transform: uppercase;
		line-height: 120%;

		@media (max-width:$screen-md - 1px){
			font-size:28px;
			bottom:20px;
		}

		@media (max-width:$screen-sm - 1px){
			font-size: 22px;
			bottom:15px;
		}
	}

	&__content{
		max-width: 476px;
		margin:0 auto;
		margin-top:27%;
		font-size: 18px;
		font-weight: 300;
		line-height: 120%;
		padding:0 15px;

		@media (max-width:$screen-md - 1px){
			font-size:16px;
			max-width: 290px;
			margin-top:15%;
		}

		@media (max-width:$screen-sm - 1px){
			width:100%;
			margin-top:30px;
		}


	}


	&__image{
		min-height: 840px;
		background-size: cover;
	
	

		&--gateway{
			background:url('/media/default/gatewayMain.jpg') no-repeat;
			min-height: 800px;
			background-size: cover;

		}

		&--gatewayRolling{
			background:url('/media/default/gateway2Main.jpg') no-repeat;
			min-height: 840px;
			background-size: cover;

			@media (max-width:$screen-md - 1px){
				min-height: 900px;
			}
		}

		&--doors{
			background:url('/media/default/doorsMain.jpg') no-repeat;
			
			@media (max-width:$screen-md - 1px){
				min-height: 550px;
			background-size: cover;
			}
		}

		&--windows{
			background:url('/media/default/windowsMain.jpg') no-repeat;
		@media (max-width:$screen-md - 1px){
				min-height: 550px;
			background-size: cover;
			}
		}


		@media (max-width:$screen-sm - 1px){
			display: none;
		}
	}

p{
	margin: 0 0 20px;
}

	&__bottomBlock{
		padding-top:50px;
		padding-bottom:50px;
		background:$color14;

		@media (max-width:$screen-md - 1px){
			padding-top:30px;
			padding-bottom:30px;
		}

	}

	&__gallery{
		margin-bottom:30px;
	}

	&__button{
		display: block;
		background:$color2;
		color:$white;
		text-transform: uppercase;
		font-size: 28px;
		font-weight: 600;
		text-align: center;
		line-height: 120%;
		margin:0 auto;
		width:891px;
		padding:38px 0;
		position:relative;
			

			@media (max-width:$screen-md - 1px){
				width:672px;
				font-size:20px;
				padding:25px 0;
			}


			@media (max-width:$screen-sm - 1px){
				max-width: 292px;
				font-size:18px;
				padding:20px 20px;
			}

		&:hover,&:focus{
			color:$white;
			text-decoration: none;
		}

	}

	&__arrow{
		position:absolute;
		right:7%;
		top:45%;

		@media (max-width:$screen-md - 1px){
			top:42%;
			right:8%;
		}

		@media (max-width:$screen-sm - 1px){
			right:5%;
		}
	}

	&__image-sm{
		display:none;

		@media (max-width:$screen-sm - 1px){
			display: block;
		}
	}



}	

.assembly{
	background: url(/media/default/assemblyBg.jpg) no-repeat;
    height: 100vh;
    background-size: cover;
    background-attachment: fixed;
    background-position: 17vw;
    position:relative;



    @media (max-width:$screen-md - 1px){
    	background-position:0;
    	height: auto;
    }
		
		&__content{
			position:absolute;
			bottom:0;
			width:100%;

			@media (max-width:$screen-md - 1px){
				position:static;
				padding-top:150px;
			}

			@media (max-width:$screen-sm - 1px){
				padding-top:100px;
			}
		}

    	&__title{
    		position:static;
			text-align:center;
    	}
	
		&__block{
			background:rgba(0,0,0,0.85);
			padding:150px 15px;
			padding-bottom:70px;
			margin-top:30px;

				@media (max-width:$screen-md - 1px){
					padding:50px 15px;
					margin-top:15px;
				}

		}
    	&__text{
    		text-align: center;
    		font-size: 20px;
    		font-weight: 300;
    		color:$white;
    		max-width: 1121px;
    		margin:0 auto;
    		margin-bottom:8%;

    			

    		@media (max-width:$screen-md - 1px){
    			font-size: 16px;
    			padding:0 15px;
    		}
    	}

}

.whyChoosePage{
    		background:url('/media/default/whyAdBg.jpg') no-repeat;
    		background-size: cover;
    		background-attachment: fixed;
    		background-position: 17vw;
    		position:relative;

    		@media (max-width:$screen-md - 1px){
    			background-position:0;
    			
    		}
		
			&__title{
				position:static;
				text-align: center;
				padding-top:300px;

				@media (max-width:$screen-md - 1px){
					padding-top:100px;
				}
			}

			&__block{
				background:rgba(0,0,0,0.85);
				padding:70px 15px;
				padding-bottom:70px;
				margin-top:30px;

				@media (max-width:$screen-md - 1px){
					padding:50px 15px;
					margin-top:15px;

				}
			}

			&__text{
				text-align: center;
    		font-size: 20px;
    		font-weight: 300;
    		color:$white;
    		max-width: 1121px;
    		margin:0 auto;
    		margin-bottom:3%;
					
					@media (max-width:$screen-md - 1px){
						font-size: 16px;
						padding:0 15px;
					}

			}


    		&__topText{
    		font-size: 42px;
    		color:$color2;
    		font-weight: 300;
    		font-family: "Roboto";
    		text-align: center;
    		margin-bottom: 5%;
    		line-height: 120%;

    		@media (max-width:$screen-md - 1px){
    			font-size: 34px;
    			margin-bottom:30px;
    			margin-top:50px;    		}

    		@media (max-width:$screen-sm - 1px){
    			font-size: 24px;

    		}
    	}

    	&__ul{
    		max-width: 1450px;
    		margin:0 auto;
    		color:$white;
    		font-size: 18px;
    		margin-bottom: 6%;

    		@media (max-width:$screen-md - 1px){
    			font-size: 16px;

    		}

    		@media (max-width:$screen-sm - 1px){
    			padding:0 25px;
    			margin-bottom:50px;
    		}
    	}

    	&__li{
    		line-height: 140%;
    		margin-bottom: 30px;
				@media (max-width:$screen-md - 1px){
					margin-bottom: 15px;
				}

				&-span{
						font-weight: bold;
    			text-decoration: underline;
				}
    	}



  }



.mobileTop{
	@media (max-width:$screen-md - 1px){
		margin-top:53px;
	}

	@media (max-width:$screen-sm - 1px){
		margin-top:43px;
	}
}