.why{
	background:url('/media/default/whyBg.jpg') top center no-repeat;
	background-size:cover;
	padding-top:67px;
	padding-bottom:107px;

		@media (max-width:$screen-md - 1px){
			padding-top:30px;
			padding-bottom:60px;
		}

		@media (max-width:$screen-sm - 1px){
			padding-bottom:40px;
		}

	&__title{
		color:$color4;
		font-weight: 300;
		font-size: 53px;
		text-transform: uppercase;
		text-align:center;

		@media (max-width:$screen-md - 1px){
			font-size:34px;
		}

		@media (max-width:$screen-sm - 1px){
			font-size:28px;
		}
	}

	&__block{
		margin:0 10%;
		margin-top:48px;

		@media (max-width:$screen-md - 1px){
			margin:0 15px;
			margin-top:45px;
		}

		@media (max-width:$screen-sm - 1px){
			margin-top:0;
		}
	}

	&__img{
		margin:0 auto;

			@media (max-width:$screen-md - 1px){
				max-width: 90%;
			}


		&--noObligations{
			margin-top:40px;


			@media (max-width:$screen-sm - 1px){
				max-width: 150px;
			}
		}	

		&--fastRealization{
			position: relative;
			right:25px;

			@media (max-width:$screen-sm - 1px){
				max-width: 126px;
				margin-top:50px;
			}
		}

		&--warranty{
			margin-top:15px;

			@media (max-width:$screen-sm - 1px){
				max-width: 124px;
				margin-top:40px;
			}
		}
	}

	&__text{
		font-size: 20px;
		font-weight: 300;
		color:$color4;
		text-align: center;
		margin-top:25px;
		line-height: 120%;

			@media (max-width:1470px){
				font-size:18px;
			}

			@media (max-width:1365px){
				font-size: 16px;
			}

			@media (max-width:$screen-sm - 1px){
				font-size: 18px;
				margin-top:15px;
			}
			

		&--span{
			font-weight: bold;
			text-transform: uppercase;
		}
	}

	&__button{
		display:block;
		padding:15px 0;
		width:231px;
		margin:0 auto;
		text-align: center;
		border:2px solid $color2;
		font-size:18px;
		color:$color2;
		font-weight: 400;

			@media (max-width:1365px){
				width:200px;
			}

			@media (max-width:$screen-sm - 1px){
				width:231px;
			}

		&:hover,&:focus{
			text-decoration: none;
			color:$color2;
		}

		&--noObligations{
			margin-top:65px;

			@media (max-width:1470px){
				margin-top:63px;
			}

			@media (max-width:$screen-md - 1px){
				margin-top:62px;
			}

			@media (max-width:$screen-sm - 1px){
				margin-top:15px;
			}
		}

		&--fastRealization{
			margin-top:35px;

			@media (max-width:$screen-sm - 1px){
				margin-top:15px;
			}
		}

		&--warranty{
			margin-top:83px;

			@media (max-width:1470px){
				margin-top:77px;
			}

			@media (max-width:$screen-md - 1px){
				margin-top:75px;
			}

			@media (max-width:$screen-sm - 1px){
				margin-top:15px;
			}
		}
	}

	&__arrow{
	    position: relative;
	    left: 10px;
	    top: -1px;
	}
}