.sidebar{
	width:364px;
	background:$white;
	box-shadow:0 0 10px 0 rgba(34,34,34,0.29);
	position:fixed;
	top:0;
	left:0;
	height:100%;
	z-index:100;
	overflow:hidden;


		@media (max-width:$screen-md - 1px){
			width:100%;
			height:auto;
			position:static;
		}

		.logo{
			margin-top:18px;
			width:359px;
			height:157px;
			background:url('/media/default/logo.jpg') no-repeat; 
			background-position:center center;
			background-size:cover;

			@media (max-width:$screen-md - 1px){
				margin-top:0px;
				width:100%;
				
			}

			@media (max-width:$screen-sm - 1px){
				height:138px;
			}
		}



		.menu{
			margin-top:40px;

			@media (max-width:$screen-md - 1px){
				margin-top:0;
				background:$color4;
				display: none;
			}

			&__collapse{
				
			}

			&__ul{
				width:261px;
				margin-left:15px;
				padding-bottom:30px;

				@media (max-width:$screen-md - 1px){
					width:100%;
					display: none;
					margin-left:0;
					height: 100vh;
					overflow-y: scroll;
					padding-bottom:130px;
					-webkit-overflow-scrolling: touch;
				}
			}

			&__li{
				float:none;
				border-bottom: 1px solid $color3;

					&:last-child{
						border-bottom: 0;
					}

				@media (max-width:$screen-md - 1px){
					border-color:$color16;
				}

				&.active{
					.menu__a{
						color:$color2;
					}
				}

				
			}

			&__a{
				color:$color1;
				font-size:14px;
				line-height: 120%;
				padding:0;
				padding-top:15px;
				padding-bottom:20px;

					@media (max-width:$screen-md - 1px){
						color:$white;
						text-align: center;
					}

				&:hover,&:focus{
					background:transparent;
					color:$color2;
				}
			}

			&__button{
				display: none;

				@media (max-width:$screen-md - 1px){
					display: block;
					font-size: 15px;
					font-family: "Roboto";
					font-weight: 300;
					color:$white;
					text-align: right;
					margin-top:10px;
					width: 80px;
    				float: right;
    				cursor:pointer;

				}

				&.closeButton{
					font-size: 0;
					width:100%;
					text-align: center;
				}
			}
		}


	&-valuation{
		background:url('/media/default/menuContBg.jpg') no-repeat;
		color:$white;
		font-weight: 400;
		text-transform: uppercase;
		margin-top:35px;
		padding-bottom:20px;
		padding-top:37px;

		&__top{
			max-width: 220px;
			text-align: center;
			margin:0 auto;
			font-size:22px;
			&--big{
				font-size: 33px;
				margin-bottom:35px;
			}
		}

		&__call{
			font-size:22px;
			float:left;
			text-align: center;
			margin-left:20px;

			&--phone{
				font-size: 30px;
				font-weight: bold;
				color:$white;
				display: block;
				position:relative;
				left:-12px;
				top:-10px;
				text-align:left;
				&:hover,&:focus{
					text-decoration: none;
					color:$white;
				}
			}
		}

		&__bottom{
			font-size: 20px;
			color:$white;
			text-decoration: underline;
			text-align: center;
			display: block;
			margin-top:20px;
				&:hover,&:focus{
					color:$white;
				}
		}



		@media (max-width:$screen-md - 1px){
			display:none;
		}

	}
}

.navbar{
	margin-bottom:0;
	min-height:auto;
}

.hamburger{
position:relative;
top:3px;
left:7px;

	.strap{
		display: block;
		height:5px;
		width:27px;
		background:$white;
		margin-top:5px;
	}
}

.hamburger.closeMenu{
	float:none;
	margin-bottom:20px;
	margin-top:20px;
	right:0;
	.strap{
		margin:0 auto;
		margin-top:5px;
		transition:transform 0.3s linear;
		&--ft{
			transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
			-moz-transform: rotate(45deg);
			-o-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
		}
		&--sd{
			display: none;
		}

		&--td{
			transform: rotate(-45deg);
			-moz-transform: rotate(-45deg);
			-webkit-transform: rotate(-45deg);
			-o-transform: rotate(-45deg);
			-ms-transform: rotate(-45deg);
			position:relative;
			top:-10px;
		}
	}
}

.fixedMenu,.fixedTop{
	position:fixed;
	width:100%;
	top:0;
	z-index:200;
}

.pageMobileMenu{
	top:53px;

	@media (max-width:$screen-sm - 1px){
		top:43px;
	}
}

.pageTopMenu{
	top:0;
}

.menu__button{
background:$color4;
width:42px;
height:42px;
position:fixed;
top:5px;
right:0;
z-index:100;
display:none;

@media (max-width:$screen-md - 1px){
	display:block;
}

}

.menu__close{
display:block;
width:20px;
height:20px;
margin:0 auto;
margin-top:23px;
margin-bottom:25px;
display:none;
	
	@media (max-width:$screen-md - 1px){
		display: block;
	}

	&Span{
	display: block;
    height: 5px;
    width: 27px;
    background: #FFFFFF;
    margin-top: 5px;
    transform:rotate(45deg);
		
		&--sd{
			transform:rotate(-45deg);
			position:relative;
			top:-10px;
		}

	}
}

.pageMenuButton{
	top:53px;
	
	@media (max-width:$screen-sm - 1px){
		top:43px;
	}
}