.recommended{
	padding:20px 35px;
	padding-bottom:70px;
		
		@media (max-width:$screen-md - 1px){
			padding:20px 20px;
		}

		@media (max-width:$screen-sm - 1px){
			padding-bottom:40px;
		}

	&__title{
		font-weight: 300;
		font-size: 53px;
		color:$color4;

		@media (max-width:$screen-md - 1px){
			font-size: 34px;
		}
		@media (max-width:$screen-sm - 1px){
			font-size: 30px;
			text-align: center;
		}
	}

	&__logo{
		margin:0 auto;
		margin-top:50px;
		max-width:1400px;

		@media (max-width:$screen-md - 1px){
			max-width: 690px;
			margin-top:20px;
		}
		
	}
	&__link{
		display: block;
	}

	&__img{
	max-width:100% !important;
	max-height:77px !important;
	width:auto !important;
	margin:0 auto;
	
	@media (max-width:$screen-sm - 1px){
		max-width: 222px !important;
	}

	}

	.owl-stage-outer{
		position:relative;
	}
	.owl-nav{
		color:$color17;
		font-size:50px;
		
	}

	.owl-prev{
		position:absolute;
		left:-15px;
		top:0px;
	}

	.owl-next{
		position:absolute;
		right:-15px;
		top:0px;
	}
}

