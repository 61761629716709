.opinion{
	background:url('/media/default/opinionBg.jpg') no-repeat;
	background-size:cover;
	padding:53px 42px;
	padding-bottom:40px;
			
			@media (max-width:$screen-md - 1px){
				padding:40px 20px;
			}

			@media (max-width:$screen-sm - 1px){
				padding-bottom:70px;
				padding-top:50px;
			}

	
	&__title{
		font-size: 53px;
		font-weight: 300;
		color:$white;
		text-transform: uppercase;

		@media (max-width:$screen-md - 1px){
			font-size: 34px;
			text-align:center;
		}

		@media (max-width:$screen-sm - 1px){
			font-size: 28px;
		}
	}

	&__block{
		margin-top:75px;
		max-width: 1140px;

		@media (max-width:$screen-md - 1px){
			margin:0 auto;
			margin-top:75px;
		}

		@media (max-width:$screen-sm - 1px){
			margin-top:20px;
		}
	}

	&__box{
		display:block;
		background:rgba(255,255,255,0.87);
		max-width: 553px;
		padding:30px 40px;
		padding-right:50px;
		margin-bottom:15px;
		height:162px;
		margin-right:15px;

		@media (max-width:1470px){
			height:180px;	
		}

		@media (max-width:$screen-sm - 1px){
			height: 170px;
			padding:25px 17px;
			margin-right: -5px;
		}

		&--bottom{
			height:199px;
			padding-right:15px;

			@media (max-width:1470px){
				height:230px;
			}

			@media (max-width:$screen-md - 1px){
				display: none;
			}
		}

	}

	&__text{
		font-size: 15px;
		font-weight: 400;
		font-style:italic;
		color:$color6;
		line-height: 120%;

			@media (max-width:$screen-md - 1px){
				font-size: 14px;
			}

		&--author{
			font-size: 16px;
			text-align: right;
			margin-top:25px;
			
			@media (max-width:$screen-sm - 1px){
				margin-top:15px;
			}
		

			&-bottom{
		    margin-top: 25px;
		    margin-right: 50px;
			}
		}
	}

	&__button{
		color:$white;
		font-size: 18px;
		font-weight: 400;
		text-align: center;	
		width:354px;
		display: block;
		margin:0 auto;
		border:2px solid $white;
		padding:15px 0;
		margin-top:55px;

			@media (max-width:$screen-sm - 1px){
				width:285px;
				line-height: 120%;
				padding:15px 50px;
			}

		&:hover,&:focus{
			color:$white;
			text-decoration: none;
		}

	}

	&__arrow{
		margin-left:5px;
	}


}