.topBeam{
	background:$color13;
	padding:10px 23px;
	border-bottom:1px solid $color14;
	position:fixed;
	width:100%;
	top:0;
	z-index:100;
		
		@media (max-width:$screen-sm - 1px){
			padding:10px 5px;
		}

	&__left{
		float:left;
	}

	&__right{
		float:left;
		margin-left: 1.1vw;
	}

	&__text{
		color:$white;
		font-size: 1vw;
		font-weight: 400;
		text-transform: uppercase;

		@media (max-width:$screen-md - 1px){
			font-size: 18px;

			&:hover,&:focus{
				color:$white;
				text-decoration: none;
			}
		}

		@media (max-width:$screen-sm - 1px){
			font-size: 13px;
		}
	}

	&__span{
		font-weight: bold;
		font-size: 1.5vw;
		margin-left:0.5vw;


		@media (max-width:$screen-md - 1px){
			font-size: 23px;
		}

		@media (max-width:$screen-sm - 1px){
			font-size: 16px;
		}
	}

	&__link{
		font-weight: bold;
		font-size: 1.5vw;
		color:$white;
		margin-left:0.5vw;

		&:hover,&:focus{
			color:$white;
			text-decoration: none;
		}

		&-form{
			text-decoration: underline;
			margin-left: 1.5vw;

			@media (max-width:1300px){
				margin-left:1vw;
			}

			&:hover,&:focus{
				color:$white;
			}
		}
	}

	@media (max-width:$screen-md - 1px){
		display: none;
	}

	&--md{
		text-align:center;
		display:none;


		@media (max-width:$screen-md - 1px){
			display: block;

		}
	}
}
